import { toastError } from "../components/Toast";
import { LOGOUT_USER } from "../redux/types/authTypes";

let errorQueue = [];
let isProcessingError = false;
let uniqueErrors = new Set();

let errorResetTimer = null;

const processErrorQueue = () => {
  if (!errorQueue.length || isProcessingError) return;

  const errorMessage = errorQueue.shift();
  isProcessingError = true;
  toastError(errorMessage);
  setTimeout(() => {
    isProcessingError = false;
    processErrorQueue();
  }, 3000);
};

// Function to clear the error queue after a specified duration if no new errors are added
const resetErrorQueue = () => {
  errorQueue = [];
  uniqueErrors.clear();
};

const handleError = (err, dispatch) => {
  let errorMessage;

  if (err && err.response) {
    if (err.response.status === 500 || err.response.status === 502) {
      toastError("It's not you, it's us. Please try again after some time");
    } else if (err.response.name === "TokenExpiredError") {
      toastError("Your session has expired, please log in again");
      localStorage.clear();
      dispatch({ type: LOGOUT_USER });
    } else if (err.response.status === 304) {
      toastError("Sorry, you can only reset your passcode once.");
    } else if (err.response.status === 401) {
      toastError("Re-authentication required");      
      localStorage.clear();
      dispatch({ type: LOGOUT_USER });
    } else {
      
      errorMessage = 
        err.response.data?.message || 
        err.response.data?.error.toString() || 
        err.response.data?.err.toString() || 
        (err.response.data?.data && err.response.data.data?.responseMessage);

      toastError(errorMessage);
    }
  } else if (err?.message === "Network Error") {
    toastError("It's not you, it's us. Please try again after some time");
  } else {
    errorMessage = err?.message || err?.error || err || "Oops... something went wrong!";
    toastError(errorMessage);
  }

  if (errorMessage && !uniqueErrors.has(errorMessage)) {
    uniqueErrors.add(errorMessage);
    errorQueue.push(errorMessage);
    processErrorQueue();

    // Reset the error queue after 10 seconds if no new error is added
    clearTimeout(errorResetTimer);
    errorResetTimer = setTimeout(resetErrorQueue, 10000); // 10 seconds in milliseconds
  }
};

export default handleError;

