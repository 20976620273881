const INITIAL_STATE = {
  menuIsCollapsed: false,
  useEnvironment: 'staging',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_MENU_STATE":
      return {
          ...state,
          menuIsCollapsed: action.payload,
      }
    case "ENV_CHANGE":
    case "UPDATE_ENVIRONMENT":
      return {
          ...state,
          useEnvironment: action.payload
      }
    default:
      return state;
  }
};