/* eslint-disable import/no-mutable-exports */
import BASE_URL, { MIXPANEL_KEY } from "./constants";

let url;
let environment;
let mixpanelKey;

const storedEnvironment = localStorage.getItem('selectedEnvironment');

if (window.location.href.includes("localhost")) {
  environment = "dev";
  mixpanelKey = MIXPANEL_KEY.dev;
  url = BASE_URL.staging;
} else if (window.location.href.includes("mookie-admin")) {
  environment = "staging";
  mixpanelKey = MIXPANEL_KEY.staging;
  url = BASE_URL.staging;
} else {
  mixpanelKey = MIXPANEL_KEY.production;
  environment = "production";
  url = BASE_URL.production;
}

if(storedEnvironment==="production"){
  mixpanelKey = MIXPANEL_KEY.production;
  environment = "production";
  url = BASE_URL.production;
} else {
  environment = "staging";
  mixpanelKey = MIXPANEL_KEY.staging;
  url = BASE_URL.staging;
}

export default url;

export { environment, mixpanelKey };