import axios from 'axios';
import { toastError, toastSuccess } from '../../components/Toast';
import { unAuthpostApi, patchApi,postApi,putApi } from '../../utils/reqClient';
import {LOGIN_USER,LOGOUT_USER} from "../types/authTypes";
import {LOADING_TRUE,LOADING_FALSE} from "../types/loadingTypes";
import handleError from '../../utils/handleError';

export function loginAdmin(userData,history) {
    return async (dispatch) => {
      try {
        dispatch({ type: LOADING_TRUE });
        const response = await postApi("admin/auth/login",userData);
        if (response.data.status) {
          toastSuccess("Login Successful")
          localStorage.clear();
          const { token} = response.data.data;
          localStorage.setItem("_m_a_t_", token);
          dispatch({ type: LOGIN_USER, payload: response.data.data });
          dispatch({ type: LOADING_FALSE });
          history.push("/dashboard");
        } else {
          dispatch({ type: LOADING_FALSE });
        }
      } catch (err) {
        dispatch({ type: LOADING_FALSE });
        handleError(err, dispatch);
      }
    };
  }
export function forgotPassword(userData,history) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_TRUE });
      const response = await patchApi("admin/auth/password",userData);
      const { success} = response.data;
      if (success) {
        dispatch({ type: LOADING_FALSE });
        toastSuccess('Password reset email sent successfully')
        history.push("/");
      } else {
        dispatch({ type: LOADING_FALSE });
      }
    } catch (err) {
      dispatch({ type: LOADING_FALSE });
      handleError(err, dispatch);
    }
  };
}
export function createPassword(userData,history) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_TRUE });
      const response = await putApi("admin/auth/password",userData);
      const { success,message} = response.data;
      if (success) {
        dispatch({ type: LOADING_FALSE });
        toastSuccess(message)
        history.push("/");
      } else {
        dispatch({ type: LOADING_FALSE });
      }
    } catch (err) {
      dispatch({ type: LOADING_FALSE });
      handleError(err, dispatch);
    }
  };
}
export function createAdmin(userData,history,token) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_TRUE });
      const response = await unAuthpostApi(`admin/team?token=${token}`,userData);
      if (response.data.status) {
        toastSuccess("Account Created Successfully")
        dispatch({ type: LOADING_FALSE });
        history.push("/");
      } else {
        dispatch({ type: LOADING_FALSE });
      }
    } catch (err) {
      dispatch({ type: LOADING_FALSE });
      handleError(err, dispatch);
    }
  };
}

export const signOut = () => dispatch => {
  // Set global loading to false
  dispatch({ type: LOADING_FALSE });
  localStorage.clear();
  dispatch({ type: LOGOUT_USER });
};