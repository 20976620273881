import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";
import {FETCH_BOOKIES,ADD_BOOKIES,
    DELETE_BOOKIES,UPDATE_BOOKIES} from "../types/bookieTypes";
   
   const INITIAL_STATE = {
     data: [],
     total: 0
   };
   
   // eslint-disable-next-line import/no-anonymous-default-export
   export default (state = INITIAL_STATE, action) => {
    const addSub = (state, action) => {
      const newSub = action.payload;
  
      const newSubList = state.data.slice(0);
      newSubList.push(newSub);
      return newSubList;
    };
    const deleteSub = (state, action) => {
      const oldSub = action.payload;
  
      const newSubList = state.data.filter(item => item.id !== oldSub);
      
      return newSubList;
    };
    const updateSub = (state, action) => {
      const subId = action.id;
  
      const newSubList = state.data.slice(0);
      const SubIndex = newSubList.findIndex(subs => subs.id === subId);
  
      newSubList[SubIndex] = action.payload;
      return newSubList;
    };
  
     switch (action.type) {
       case FETCH_BOOKIES:
          return {
              ...state,
              data: action.payload,
              total: action.total,
          }
        case ADD_BOOKIES: 
          return{
            ...state,
            data: addSub(state,action)
          }
        case DELETE_BOOKIES: 
          return{
            ...state,
            data: deleteSub(state,action)
          }
        case UPDATE_BOOKIES:
          return{
            ...state,
            data: updateSub(state,action)
          }
        case ENV_CHANGE:
        case LOGOUT_USER:
          return INITIAL_STATE
       default:
         return state;
     }
   };