export const FETCH_GAMES = 'FETCH_GAMES';
export const FETCH_REPORT = "FETCH_REPORT";
export const SET_GAME_ID = "SET_GAME_ID";
export const FETCH_ALL_FIXTURES = "FETCH_ALL_FIXTURES";
export const ADD_GAME = "ADD_GAME";
export const EDIT_GAME = "EDIT_GAME";
export const FETCH_REFERRALS = "FETCH_REFERRALS";
export const DELETE_GAME = "DELETE_GAME";
export const CREATE_REFERRAL = "CREATE_REFERRAL";
export const EDIT_REFERRAL = "EDIT_REFERRAL";
export const ADD_BANNER = "ADD_BANNER";
export const REORDER_BANNERS = "REORDER_BANNERS";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const SET_BANNERS = "SET_BANNERS";
export const DELETE_BANNERS = "DELETE_BANNERS";
export const GET_CONFIG = "GET_CONFIG";
export const SET_SEASONS = "SET_SEASONS";
export const GET_RATINGS = "GET_RATINGS";
export const GET_PARTICIPATION = "GET_PARTICIPATION";
export const UPDATE_MAPPING = "UPDATE_MAPPING";
export const SET_BUCKETS = "SET_BUCKETS";
export const WAITLIST_STATS = "WAITLIST_STATS";
export const PREDICTION_STATS = "PREDICTION_STATS";
export const FETCH_GOOGLE_ANALYTICS = "FETCH_GOOGLE_ANALYTICS";
export const FETCH_GAME_DETAILS = "FETCH_GAME_DETAILS";
export const SET_STARTED_GAMES = "SET_STARTED_GAMES";
export const RESET_PREDICTION_STATS = "RESET_PREDICTION_STATS";
export const SET_PREDICTION_STATS = "SET_PREDICTION_STATS";
export const SET_REPORTS = "SET_REPORTS";
export const SET_PAST_GAMES = "SET_PAST_GAMES";